<template>
    <div class="form-horizontal" :id="modalId + '-form'">

        <div class="form-group" :class="{ 'has-error': errors.has('name') }">
            <label for="testimonial-name" class="col-md-3 control-label">Name</label>
            <div class="col-md-7">
                <input id="testimonial-name" type="text" class="form-control" data-vv-name="name" placeholder="Jane S." v-model="form.name" @keydown.enter.prevent>
                <span class="help-block" v-if="errors.has('name')">{{ errors.first('name') }}</span>
            </div>
        </div>

        <div class="form-group" :class="{ 'has-error': errors.has('title') }">
            <label for="testimonial-title" class="col-md-3 control-label">Title</label>
            <div class="col-md-7">
                <input id="testimonial-title" type="text" class="form-control" data-vv-name="title" placeholder="Event Manager" v-model="form.title" @keydown.enter.prevent>
                <span class="help-block" v-if="errors.has('title')">{{ errors.first('title') }}</span>
            </div>
        </div>

        <div class="form-group" :class="{ 'has-error': errors.has('organization') }">
            <label for="testimonial-organization" class="col-md-3 control-label">Organization</label>
            <div class="col-md-7">
                <input id="testimonial-organization" type="text" class="form-control" data-vv-name="organization" v-validate="'required'" placeholder="ACME Co." v-model="form.organization" @keydown.enter.prevent>
                <span class="help-block" v-if="errors.has('organization')">{{ errors.first('organization') }}</span>
            </div>
        </div>

        <div class="form-group" :class="{ 'has-error': errors.has('event_date') }">
            <label for="testimonial-event_date" class="col-md-3 control-label">Event Date</label>
            <div class="col-md-7">
                <input id="testimonial-event_date" type="text" class="form-control" data-vv-name="event_date" placeholder="8/10/2012" v-model="form.event_date" @keydown.enter.prevent>
                <span class="help-block" v-if="errors.has('event_date')">{{ errors.first('event_date') }}</span>
            </div>
        </div>

        <div class="form-group" :class="{ 'has-error': errors.has('text') }">
            <label for="testimonial-text" class="col-md-3 control-label">Testimony</label>
            <div class="col-md-7">
                <textarea id="testimonial-text" data-vv-name="text" class="form-control" cols="10" rows="5" v-validate="'required'" v-model="form.text" @keydown.enter.prevent></textarea>
                <span class="help-block" v-if="errors.has('text')">{{ errors.first('text') }}</span>
            </div>
        </div>

    </div>
</template>

<script>
    export default {

        data() {
            return {
                form: {
                    name: '',
                    title: '',
                    organization: '',
                    event_date: '',
                    text: ''
                }
            };
        },

        props: {
            modalId: String,
            editData: Object,
            identifier: {
                type: Number,
                default: null
            }
        },

        watch: {
            editData: {
                deep: true,
                handler(val) {
                    Object.assign(this.form, val);
                }
            }
        },

        mounted() {
            this.prepareComponent();
        },

        methods: {

            prepareComponent() {
                this.$validator.localize({
                    en: {
                        messages: {
                            required: () => 'This field is required.'
                        }
                    }
                });

                this.$parent.$on('save', this.handleSave);
            },

            handleSave() {
                this.$validator.validateAll().then(result => {

                    if(result) {
                        Event.fire('posted-testimonial', this.form, this.identifier);

                        this.$validator.reset();

                        $('#' + this.modalId).modal('hide');

                        this.reset();
                    }

                });
            },

            reset() {
                Object.assign(this.$data, this.$options.data.call(this));
            }

        }
    }
</script>