<template>
    <div class="list-group-item alterable" :id="'presentation' + index">

        <span class="handle"></span>

        <h4 class="list-group-item-heading">
            {{ presentation.title }}
        </h4>
        <div class="small" style="padding:2px 0">
            <div v-if="!!type">
                <strong class="text-uppercase small inline-block">Type:</strong>
                <span v-html="type"></span>
            </div>
            <div v-if="!!style">
                <strong class="text-uppercase small inline-block">Style(s):</strong>
                <span v-html="style"></span>
            </div>
        </div>
        <p class="list-group-item-text small">
            {{ excerpt }}
        </p>

        <div class="item-toolbar">
            <div class="btn-group btn-group-sm" role="group" aria-label="...">
                <button type="button" class="btn btn-info" title="Edit" tabindex="-1" @click="editItem">
                    <span class="glyphicon glyphicon-edit" aria-hidden="true"></span>
                </button>
                <button type="button" class="btn btn-danger" title="Delete" tabindex="-1" @click="deleteItem">
                    <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>
                </button>
            </div>
        </div>

        <input type="hidden" :name="'presentations['+index+'][title]'" v-model.trim="presentation.title">
        <input type="hidden" :name="'presentations['+index+'][presentation_type_id]'" v-model.trim="presentation.presentation_type_id">
        <template v-for="(id, i) in presentation.style_ids">
            <input type="hidden" :name="'presentations['+index+'][style_ids]['+i+']'" :value="id">
        </template>
        <!-- <input type="hidden" :name="'presentations['+index+'][topic_id]'" v-model.trim="presentation.topic_id"> -->
        <input type="hidden" :name="'presentations['+index+'][description]'" v-model.trim="presentation.description">
        <input type="hidden" :name="'presentations['+index+'][id]'" v-if="hasOwnProperty.call(presentation, 'id')" v-model="presentation.id">
    </div>
</template>

<script>
    import { limitWords, stripHtml } from '../../helpers.js';

    export default {
        props: {
            index: {
                type: Number,
                required: true
            },
            presentation: {
                type: Object,
                required: true
            },
            styles: {
                type: [Object, Array],
                required: true
            },
            topics: [Object, Array],
            types: {
                type: [Object, Array],
                required: true
            }
        },

        computed: {
            style() {
                if(!this.presentation.hasOwnProperty('style_ids') || this.presentation.style_ids.length == 0) {
                    return false;
                }

                var self = this;
                return this.presentation.style_ids.map(s => {
                    return '<span class="label label-default inline-block">' +
                        self.styles[s] + '</span>';
                }).join(' ');
            },
            topic() {
                return this.topics[ this.presentation.topic_id ] || 'General';
            },
            type() {
                if(!!this.presentation.presentation_type_id) {
                    return '<span class="label label-default inline-block">' +
                        this.types[ this.presentation.presentation_type_id ] +
                        '</span>';
                }
                return false;
            },
            excerpt() {
                return limitWords( stripHtml(this.presentation.description), 35 );
            }
        },

        methods: {
            editItem() {
                this.$emit('edit', this.presentation, this.index);
            },

            deleteItem() {
                if(confirm('Are you sure you want to delete this entry?')) {
                    this.$emit('delete', this.index)
                }
            }
        }
    }
</script>