import { cleanPastedHTML } from './helpers.js';

$(function(){

    $('#start_year').on('input change', function(){
        var $el  = $(this);
        var $tar = $('#speak-for');
        if($el.val().length == 4) {
            var diff = Math.abs((new Date()).getFullYear() - $el.val())
            if(diff > 0) {
                $tar.html("You've been speaking for " + diff + " years.");
            }
            return;
        }
        $tar.html('');
    });

    $('.charlimit').characterCounter({
        limit: 255,
        counterWrapper: 'div',
        counterExceededCssClass: 'exceeded text-danger'
    });

    $('.summernote').summernote({
        airMode: false,
        codemirror: false,
        dialogsInBody: true,
        disableDragAndDrop: true,
        height: 300,
        minHeight: 200,
        // popover: {
        //     image: [],
        //     link: ['link', ['linkDialogShow', 'unlink']],
        //     air: []
        // },
        shortcuts: false,
        toolbar: [
            ['style', ['bold', 'italic', 'underline', 'clear']],
            ['font', ['superscript', 'subscript']],
            ['para', ['ul', 'ol', 'paragraph']],
            ['insert', ['link']],
            ['ctrl', ['undo', 'redo']],
            ['misc', ['fullscreen']]
        ],
        callbacks: {
            onPaste(event) {
                var thisNote = $(this);
                var updatePastedText = function(editor){
                    var original = editor.summernote('code');
                    var cleaned = cleanPastedHTML(original);
                    editor.summernote('code', cleaned);
                };
                setTimeout(function () {
                    //this kinda sucks, but if you don't do a setTimeout,
                    //the function is called before the text is really pasted.
                    updatePastedText(thisNote);
                }, 10);
            }
        }
    }).summernote('removeModule', 'autoLink');

});