<template>
    <div>
        <draggable
          class="list-group media-group"
          v-model="videos"
          group="videos"
          handle=".handle"
          draggable=".list-group-item"
          v-if="videos.length > 0"
        >
            <div class="list-group-item media-group-item alterable" v-for="(video, index) in videos">
                <span class="handle"></span>
                <div class="media">
                    <div class="media-left">
                        <div class="media-object" :style="'background-image:url('+video.thumbnail+')'">
                        </div>
                    </div>
                    <div class="media-body">
                        <div class="content force-break">
                            <h4 class="media-heading">{{ video.title }}</h4>
                            <a target="_blank" :href="video.url">{{ video.url }}</a>
                        </div>
                        <span :class="'provider '+video.provider"></span>
                    </div>
                    <input type="hidden" :name="'videos['+index+'][url]'" v-model="video.url">
                    <input type="hidden" :name="'videos['+index+'][title]'" v-model="video.title">
                    <input type="hidden" :name="'videos['+index+'][provider]'" v-model="video.provider">
                    <input type="hidden" :name="'videos['+index+'][video_id]'" v-model="video.video_id">
                    <input type="hidden" :name="'videos['+index+'][id]'" v-if="hasOwnProperty.call(video, 'id')" v-model="video.id">
                </div><!--/.media-->
                <div class="item-toolbar">
                    <div class="btn-group btn-group-xs" role="group" aria-label="...">
                        <!-- <button type="button" class="btn btn-info" title="Edit" tabindex="-1" @click="video.isEditing = true">
                            <span class="glyphicon glyphicon-edit" aria-hidden="true"></span>
                        </button> -->
                        <button type="button" class="btn btn-danger" title="Delete" tabindex="-1" @click="deleteRow(index)">
                            <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div><!--/.list-group-item-->
        </draggable><!--/.list-group-->

        <div class="well well-sm">
            <div class="alert alert-danger" v-if="addError">
                <strong>{{ addError.strong }}</strong> {{ addError.msg }}
            </div>
            <div class="form-group" :class="{ 'has-error': addError }" style="margin-bottom:0">
                <label for="add-media-video" class="col-md-3 control-label">Add Video URL</label>
                <div class="col-md-9">
                    <div class="input-group">
                        <input type="text" class="form-control" placeholder="https://www.youtube.com/watch?v=xxxxxxxxxxx" id="add-media-video" :disabled="isSearching" v-model="inputValue">
                        <span class="input-group-btn">
                            <button type="button" class="btn btn-default" @click="retrieveDetails" :disabled="isSearching">
                                <span class="glyphicon glyphicon-plus" aria-hidden="true" v-if="!isSearching"></span>
                                <span class="glyphicon glyphicon-refresh glyph-spin" aria-hidden="true" v-if="isSearching"></span>
                            </button>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import draggable from 'vuedraggable';

    export default {

        data() {
            return {
                addError: false,
                inputValue: '',
                isSearching: false,
                videos: []
            }
        },

        props: {
            old: {
                type: Array,
                default: function() {
                    return [];
                }
            }
        },

        mounted() {
            this.prepareComponent();
        },

        components: {
            draggable
        },

        methods: {

            prepareComponent() {
                var self = this;

                if(this.old.length) {
                    this.old.forEach(function(video, i){
                        if(!video.hasOwnProperty('thumbnail') || video.thumbnail == '') {
                            var thumbnail = self.retrieveThumbnail(video);

                            Promise.resolve(thumbnail).then(function(value){
                                video.thumbnail = value;

                                self.addRow(video);
                            });
                        } else {
                            self.addRow(video);
                        }
                    });
                }
            },

            emptyRow() {
                return {
                    thumbnail: '',
                    title: '',
                    provider: '',
                    video_id: '',
                    url: '',
                    isFound: false,
                    isEditing: false
                };
            },

            addRow(video) {
                this.videos.push(
                    Object.assign( this.emptyRow(), video )
                );
            },

            deleteRow(index) {
                var self = this;

                if(index in this.videos) {
                    if(!confirm('Are you sure you want to delete this video?')) {
                        return;
                    }

                    var video = this.videos[index];

                    if(video.hasOwnProperty('id') && video.id) {
                        axios.delete('/videos/'+video.id)
                            .catch(e => console.log(e))
                            .then(x => x.data)
                            .then(x => {
                                self.videos.splice(index, 1);
                                // notify?
                                return;
                            });
                    } else {
                        this.videos.splice(index, 1);
                        return;
                    }
                }
                console.log('Video ['+index+'] does not exist.');

            },

            editRow(video) {
                video.isEditing = true;
            },

            retrieveDetails() {
                var self = this;

                var have = this.videos.filter(function(video){
                    return video.url == self.inputValue;
                });

                if(have.length) {
                    self.addError = {
                        strong: 'Ooops!',
                        msg: "You've already added this video."
                    };
                    return;
                }

                this.isSearching = true;
                this.addError = false;

                this.fetchVideo( this.inputValue )
                    .catch(error => {
                        self.isSearching = false;

                        if(error.response) { // actual error response
                            // code = error.response.status;
                            self.addError = error.response.data;
                        } else if (error.request) { // no response
                            console.log(error.request);
                            self.addError = {
                                strong: 'Oops!',
                                msg: error.request
                            };
                        } else { // major error
                            self.addError = {
                                strong: 'Oops!',
                                msg: error.message
                            };
                            console.log('Error', error.message);
                        }
                    })
                    .then(response => {
                        if(!response) {
                            return;
                        }
                        var r = response.data;

                        var video = {
                            title: r.title,
                            thumbnail: r.thumbnail_url,
                            url: self.inputValue,
                            isFound: true,
                            video_id: r.video_id
                        };

                        if(r.provider_name === 'Vimeo') {
                            video.provider = 'vimeo';
                        } else if(r.provider_name === 'YouTube') {
                            video.provider = 'youtube';
                        }

                        self.addRow( video );

                        self.reset();
                    });
            },

            retrieveThumbnail(video) {
                if(video.provider == 'youtube') {
                    return 'https://i.ytimg.com/vi/'+video.video_id+'/default.jpg';
                } else if(video.provider == 'vimeo') {
                    return axios.get('https://vimeo.com/api/v2/video/'+video.video_id+'.json', {
                        transformRequest: function(data, headers) {
                            delete headers.common['X-CSRF-TOKEN'];
                            delete headers.common['X-Requested-With'];
                            return data;
                        }
                    })
                        .catch(error => {
                            console.log('Thumbnail Fetch Error', error);
                            return;
                        })
                        .then(response => {
                            if(!response) {
                                return;
                            }

                            return response.data[0].thumbnail_small;
                        });
                }
            },

            fetchVideo(url) {
                return axios.get('/media/video', {
                    params: { url: url }
                });
            },

            reset() {
                this.inputValue  = '';
                this.addError    = false;
                this.isSearching = false;
            }
        }
    }
</script>
