<template>
    <div class="form-horizontal" :id="modalId + '-form'">

        <div class="form-group" :class="{ 'has-error': errors.has('title') }">
            <label for="presentation-title" class="col-md-3 control-label">Title</label>
            <div class="col-md-7">
                <input id="presentation-title" type="text" class="form-control" data-vv-name="title" placeholder="Some Exciting Title" v-validate="'required'" v-model="form.title" @keydown.enter.prevent>
                <span class="help-block" v-if="errors.has('title')">{{ errors.first('title') }}</span>
            </div>
        </div>

        <div class="form-group" :class="{ 'has-error': errors.has('type') }">
            <label for="presentation-type" class="col-md-3 control-label">Type</label>
            <div class="col-md-7">
                <select id="presentation-type" class="form-control" data-vv-name="type" v-validate="'required'" v-model="form.presentation_type_id" @keydown.enter.prevent>
                    <option v-for="(t, key) in types" :value="key">{{ t }}</option>
                </select>
                <span class="help-block" v-if="errors.has('type')">{{ errors.first('type') }}</span>
            </div>
        </div>

        <!-- <div class="form-group" :class="{ 'has-error': errors.has('style') }">
            <label for="presentation-style" class="col-md-3 control-label">Style</label>
            <div class="col-md-7">
                <select id="presentation-style" class="form-control" data-vv-name="style" v-model="form.presentation_style_id" @keydown.enter.prevent>
                    <option value="0"></option>
                    <option v-for="(s, key) in styles" :value="key">{{ s }}</option>
                </select>
                <span class="help-block" v-if="errors.has('style')">{{ errors.first('style') }}</span>
            </div>
        </div> -->

        <div class="form-group" :class="{ 'has-error': errors.has('style_ids') }">
            <label for="presentation-style" class="col-md-3 control-label">Styles</label>
            <div class="col-md-7">
                <pretty-select
                    field-name="style_ids"
                    :is-multiple="true"
                    :options="styles"
                    :old.sync="form.style_ids"></pretty-select>
                <span class="help-block" v-if="errors.has('style_ids')">{{ errors.first('style_ids') }}</span>
            </div>
        </div>

        <!--
        <div class="form-group" :class="{ 'has-error': errors.has('topic') }">
            <label for="presentation-topic" class="col-md-3 control-label">Topic</label>
            <div class="col-md-7">
                <select id="presentation-topic" class="form-control" data-vv-name="topic" v-validate="'required'" v-model="form.topic_id" @keydown.enter.prevent>
                    <option v-for="(topic, key) in topics" :value="key">{{ topic }}</option>
                </select>
                <span class="help-block" v-if="errors.has('topic')">{{ errors.first('topic') }}</span>
            </div>
        </div>
        -->

        <div class="form-group" :class="{ 'has-error': errors.has('description') }">
            <label for="presentation-description" class="col-md-3 control-label">Description</label>
            <div class="col-md-7">
                <textarea id="presentation-description" data-vv-name="description" class="form-control" cols="10" rows="5" v-validate="'required'" v-model="form.description" @keydown.enter.prevent></textarea>
                <span class="help-block" v-if="errors.has('description')">{{ errors.first('description') }}</span>
            </div>
        </div>

    </div>
</template>

<script>
    import PrettySelect from '../utility/PrettySelect.vue';
    import { ErrorBag } from 'vee-validate';
    import { cleanPastedHTML } from '../../helpers.js';

    export default {

        data() {
            return {
                form: {
                    title: '',
                    style_ids: [],
                    presentation_type_id: 1,
                    topic_id: 0,
                    description: ''
                }
            };
        },

        props: {
            modalId: String,
            editData: Object,
            identifier: {
                type: Number,
                default: null
            },
            topics: [Object, Array],
            styles: [Object, Array],
            types: [Object, Array]
        },

        watch: {
            editData: {
                deep: true,
                handler(val) {
                    Object.assign(this.form, val);
                    if(val.hasOwnProperty('description') && val.description !== '') {
                        $('#presentation-description', this.formId)
                            .summernote('code', val.description);
                    }
                }
            }
        },

        components: {
            'pretty-select': PrettySelect,
        },

        computed: {
            formId() {
                return '#'+this.modalId+'-form';
            }
        },

        mounted() {
            this.prepareComponent();
        },

        methods: {

            prepareComponent() {
                this.$validator.localize({
                    en: {
                        messages: {
                            required: () => 'This field is required.'
                        }
                    }
                });

                this.$parent.$on('save', this.handleSave);

                this.activateSummernote();
            },

            handleSave() {
                this.$validator.validateAll().then(result => {

                    if(result) {
                        Event.fire('posted-presentation', this.form, this.identifier);

                        this.$validator.reset();

                        $('#' + this.modalId).modal('hide');

                        this.reset();
                    }

                });
            },

            reset() {
                Object.assign(this.$data, this.$options.data.call(this));
                this.$emit('select-change');
                $('#presentation-description', this.formId).summernote('reset');
            },

            activateSummernote() {
                var component = this;

                $('#presentation-description', this.formId).summernote({
                    airMode: false,
                    codemirror: false,
                    dialogsInBody: true,
                    disableDragAndDrop: true,
                    height: 300,
                    minHeight: 200,
                    // popover: {
                    //     image: [],
                    //     link: [],
                    //     air: []
                    // },
                    shortcuts: false,
                    toolbar: [
                        ['style', ['bold', 'italic', 'underline']],
                        ['font', ['superscript', 'subscript']],
                        ['para', ['ul', 'ol', 'paragraph']],
                        ['insert', ['link']],
                        ['ctrl', ['undo', 'redo']]
                        // ,['misc', ['codeview']]
                    ],
                    callbacks: {
                        onBlur(event) {
                            if($(this).summernote('isEmpty')) {
                                component.form.description = '';
                            } else {
                                component.form.description = $(this).summernote('code');
                            }
                        },
                        onChange(contents) {
                            if(!$(this).summernote('isEmpty')) {
                                component.errors.remove('description');
                            }
                        },
                        onPaste(event) {
                            var thisNote = $(this);
                            var updatePastedText = function(editor){
                                var original = editor.summernote('code');
                                var cleaned = cleanPastedHTML(original);
                                editor.summernote('code', cleaned);
                            };
                            setTimeout(function () {
                                //this kinda sucks, but if you don't do a setTimeout,
                                //the function is called before the text is really pasted.
                                updatePastedText(thisNote);
                            }, 10);
                        }
                    }
                }).summernote('removeModule', 'autoLink');
            }

        }
    }
</script>