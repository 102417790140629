<template>
    <div>
        <draggable class="list-group"
            v-model="testimonials"
            group="testimonials"
            handle=".handle"
            draggable=".list-group-item"
            v-if="testimonials.length > 0"
        >
            <testimonial-list-item v-for="(testimonial, index) in testimonials"
                :testimonial="testimonial"
                :key="index"
                :index="index"
                @edit="editItem(...arguments)"
                @delete="deleteItem"></testimonial-list-item>
        </draggable>
        <div class="well text-center" v-else>
            Add a testimonial by clicking the <em>Add Testimonial</em> button&nbsp;below.
        </div>

        <utility-modal id="TestimonialModal">

            <template slot="heading">Edit Testimonial</template>

            <testimonial-form
                slot-scope="props"
                :modal-id="props.modalId"
                :editData="editForm"
                :identifier="editIndex"></testimonial-form>

        </utility-modal>
    </div>
</template>

<script>
    import draggable from 'vuedraggable';
    import UtilityModal from '../utility/UtilityModal.vue';
    import TestimonialListItem from './TestimonialListItem.vue';
    import TestimonialForm from './TestimonialForm.vue';

    export default {
        /*
         * The component's data.
         */
        data() {
            return {
                testimonials: [],
                editIndex: null,
                editForm: {
                    name: '',
                    position: '',
                    organization: '',
                    event_date: '',
                    text: ''
                }
            };
        },

        props: {
            old: {
                type: Array,
                default: function() {
                    return [];
                }
            }
        },

        components: {
            draggable,
            'utility-modal': UtilityModal,
            'testimonial-list-item': TestimonialListItem,
            'testimonial-form': TestimonialForm
        },

        mounted() {
            this.prepareComponent();
        },

        methods: {

            prepareComponent() {
                if(this.old.length) {
                    this.testimonials = this.old;
                }

                Event.listen('posted-testimonial', this.saveItem);
            },

            saveItem(testimonial, index) {
                if(typeof index !== 'undefined' && index in this.testimonials) {
                    // this.$set(this.testimonials, index, testimonial);
                    this.testimonials.splice(index, 1, testimonial);

                    this.editIndex = null;
                    Object.assign(this.editForm, this.$options.data.call(this).editForm);
                    return;
                }
                this.testimonials.push(testimonial);
            },

            editItem(data, index) {
                Object.assign(this.editForm, data);
                this.editIndex = index;

                $('#TestimonialModal').modal('show');
            },

            deleteItem(index) {
                var self = this;

                if(index in this.testimonials) {
                    var testimonial = this.testimonials[index];

                    if(testimonial.hasOwnProperty('id') && testimonial.id) {
                        axios.delete('/testimonials/'+testimonial.id)
                            .catch(e => console.log(e))
                            .then(x => x.data)
                            .then(x => {
                                self.testimonials.splice(index, 1);

                                // notify?

                                return;
                            });
                    } else {
                        this.testimonials.splice(index, 1);
                        return;
                    }
                }
                console.log('Testimonial ['+index+'] does not exist.');
            },
        }
    }
</script>
