<template>
    <div class="modal fade" :id="id" tabindex="-1" role="dialog" :aria-labelledby="id + 'Label'">
        <div class="modal-dialog" :class="{'modal-sm': isSmall, 'modal-lg': isLarge}" role="document">
            <div class="modal-content">

                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <h4 class="modal-title" :id="id + 'Label'">
                        <slot name="heading"></slot>
                    </h4>
                </div><!--/.modal-header-->

                <div class="modal-body">
                    <slot :modal-id="id"></slot>
                </div><!--./modal-body-->

                <div class="modal-footer">
                    <slot name="footer">
                        <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>
                        <button type="button" class="btn btn-primary" @click="$emit('save')">Save</button>
                        <!-- <button type="button" class="btn btn-default" data-dismiss="modal">Close</button> -->
                    </slot>
                </div><!--/.modal-footer-->

            </div><!--/.modal-content-->
        </div><!--/.modal-dialog-->
    </div><!--/.modal-->
</template>

<script>
    export default {

        props: {
            id: {
                type: String,
                default: ''
            },
            isSmall: {
                type: Boolean,
                default: false
            },
            isLarge: {
                type: Boolean,
                default: false
            }
        },

        mounted() {
            this.prepareComponent();
        },

        methods: {

            prepareComponent() {
                //
            }
        }
    }
</script>