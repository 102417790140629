<template>
    <div>
        <div class="row">
            <div class="col-sm-5 col-sm-offset-1">

                <label class="control-label">Upload a profile photo</label>
                <p>Click the button below to upload a photo. Ideally the photo should be square, and a minimum of 500px wide by 500px tall.</p>

                <div class="photo-actions">
                    <label class="btn btn-primary btn-upload">
                        <input type="file" class="input-file" accept="image/*"
                            ref="fileInput"
                            @change="setupFile">
                        <span>Select new photo</span>
                    </label>
                </div>

                <input type="hidden" name="photo_id" :value="mediaObject.id || ''">
            </div>
            <div class="col-sm-5">

                <div class="speaker-photo-wrap" :class="{processing: isSaving}">
                    <span class="speaker-photo" role="img" :style="backgroundImage"></span>
                    <span class="glyphicon glyphicon-refresh glyph-spin" aria-hidden="true"></span>
                </div>

            </div>
        </div><!--/.row-->

        <utility-modal id="croppieModal" :is-small="true">
            <template slot="heading">
                Resize &amp; Crop
            </template>
            <div class="alert alert-danger" v-if="isFailed">
                <strong>Upload failed. Try again.</strong>
                {{ uploadError }}
            </div>
            <div class="image-cropper">
                <div id="croppie"></div>
            </div>
            <template slot="footer">
                <button type="button" class="btn btn-default" data-dismiss="modal" :disabled="isSaving">Cancel</button>
                <button type="button" class="btn btn-primary" @click="uploadCropped" :disabled="isSaving">Save</button>
            </template>
        </utility-modal>
    </div>
</template>

<script>
    import Croppie from 'croppie';
    import Modal from '../utility/UtilityModal.vue';
    import { isObject } from '../../helpers.js';

    const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_FAILED = 2;
    const DEFAULT_BKGD = 'https://www.gravatar.com/avatar/cdc984cbc53b2ee98b6c7c4ccadd9998.jpg?s=250&d=mm';

    export default {

        data() {
            return {
                croppie: null,
                image: null,
                mediaObject: {},
                modalElement: null,
                selectedFile: null,
                status: null,
                uploadError: null
            };
        },

        props: {
            croppieType: {
                type: String,
                default: 'square' // circle | square
            },
            imgUrl: String,
            old: {
                type: Object,
                default: function() {
                    return {};
                }
            }
        },

        computed: {
            isInitial() {
                return this.status === STATUS_INITIAL;
            },
            isSaving() {
                return this.status === STATUS_SAVING;
            },
            isFailed() {
                return this.status === STATUS_FAILED;
            },
            backgroundImage() {
                var image = this.mediaObject.id ? this.mediaObject.url : DEFAULT_BKGD;
                return "background-image:url('"+image+"')";
            }
        },

        components: {
            'utility-modal': Modal
        },

        mounted() {
            this.prepareComponent()
        },

        methods: {

            prepareComponent() {
                var self = this;

                this.status = STATUS_INITIAL;

                this.$on('imageread', function(image){
                    self.image = image;
                    self.modalElement.modal('show');
                });

                this.modalElement = $('#croppieModal');
                this.modalElement.on('shown.bs.modal', function(){
                    self.destroyCroppie();
                    self.setupCroppie();
                }).on('hide.bs.modal', function(){
                    self.destroyCroppie();
                    self.reset();
                });

                if(isObject(this.old) && this.old.hasOwnProperty('id')) {
                    this.setPhoto(this.old);
                }
            },

            setupCroppie() {
                let el = document.getElementById('croppie')
                this.croppie = new Croppie(el, {
                    viewport: { width: 235, height: 235, type: this.croppieType },
                    boundary: { width: 250, height: 250 },
                    showZoomer: true,
                    enableOrientation: true,
                    mouseWheelZoom: false
                });
                this.croppie.bind({
                    url: this.image
                });
            },

            destroyCroppie() {
                if(this.croppie === null) {
                    return;
                }

                this.croppie.destroy();
                this.croppie = null;
            },

            setupFile(event) {
                var self = this;

                let files = event.target.files || event.dataTransfer.files;
                if(!files.length || typeof files[0] === 'undefined' || !files[0].size) {
                    return;
                }

                this.selectedFile = files[0];

                var reader = new FileReader();
                reader.onload = (e) => {
                    self.resetInput();
                    self.$emit('imageread', e.target.result);
                };
                reader.readAsDataURL(this.selectedFile);
            },

            uploadCropped() {
                var self = this;

                this.status = STATUS_SAVING;

                // format: 'png', // jpeg|png|webp
                // quality: 1, // 0 to 1
                // circle: false // true|false
                this.croppie.result({
                    type: 'canvas', // canvas|base64|html|blob|rawcanvas
                    size: 'original' //viewport|original|{width, height}
                }).then(response => {
                    self.image = response;

                    var formData = new FormData();
                    formData.append('cropped', response);
                    formData.append('name', self.selectedFile.name);
                    formData.append('mime', self.selectedFile.type);

                    axios.post('/speakers/add_cropped_photo', formData)
                        .catch(err => {
                            self.uploadError = err.response.data;
                            self.status = STATUS_FAILED;
                        })
                        .then(x => x.data)
                        .then(x => {
                            if(x.hasOwnProperty('photo')) {
                                self.reset();

                                self.mediaObject = x.photo;
                                self.mediaObject.url = `/storage/${x.photo.local_uri}`;

                                self.modalElement.modal('hide');
                            }
                        });
                });
            },

            setPhoto(photo) {
                this.mediaObject = Object.assign(
                    {},
                    photo,
                    { url: `/storage/${photo.local_uri}` }
                );
            },

            reset() {
                this.image = null;
                this.selectedFile = null;
                this.status = STATUS_INITIAL;
                this.uploadError = null;
                this.resetInput();
            },

            resetInput() {
                this.$refs.fileInput.value = '';
            }

        }
    }
</script>

<style lang="scss">
    .image-cropper {
        margin-left: auto;
        margin-right: auto;
        width: 250px;
    }

    .btn-upload {
        cursor: pointer;
        overflow: hidden;
        position: relative;

        &.disabled {
            cursor: no-drop;
        }

        .input-file {
            bottom: 0;
            cursor: pointer;
            left: 0;
            opacity: 0; /* invisible but it's there! */
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
        }
    }
</style>