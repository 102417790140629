export function formatDate(date) {
    var date = new Date(date);
    if(isNaN(date)) {
        return '';
    }

    return [
        (date.getMonth() + 1),
        date.getDate(),
        date.getFullYear()
    ].join('/');
}

export function limitWords(string, limit) {
    if(string === '') return '';

    limit = (typeof limit !== 'undefined') ? limit : 15;

    var words = string.split(' ');
    if(words.length > limit) {
        return words.splice(0, limit).join(' ') + '\xa0\u2026';
    }
    return string;
}

export function stripHtml(html) {
    var tmp = document.createElement('DIV');
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || '';
}

export function isArray(variable) {
    return !!variable && variable.constructor === Array;
}

export function isObject(variable) {
    return !!variable && variable.constructor === Object;
}

export function decodeHtml(html) {
    var txt = document.createElement('textarea');
        txt.innerHTML = html;
    return txt.value;
}

export function cleanPastedHTML(input) {
    // 1. remove line breaks / Mso classes
    var stringStripper = /(\n|\r| class=(")?Mso[a-zA-Z]+(")?)/g;
    var output = input.replace(stringStripper, ' ');
    // 2. strip Word generated HTML comments
    var commentSripper = new RegExp('<!--(.*?)-->','g');
    var output = output.replace(commentSripper, '');
    var tagStripper = new RegExp('<(/)*(meta|link|span|\\?xml:|st1:|o:|font)(.*?)>','gi');
    // 3. remove tags leave content if any
    output = output.replace(tagStripper, '');
    // 4. Remove everything in between and including tags '<style(.)style(.)>'
    var badTags = ['style', 'script', 'applet', 'embed', 'noframes', 'noscript', 'canvas', 'iframe'];

    for (var i=0; i< badTags.length; i++) {
        tagStripper = new RegExp('<'+badTags[i]+'.*?'+badTags[i]+'(.*?)>', 'gi');
        output = output.replace(tagStripper, '');
    }
    // 5. remove attributes ' style="..."'
    var badAttributes = ['style', 'start', 'class'];
    for (var i=0; i< badAttributes.length; i++) {
        var attributeStripper = new RegExp(' ' + badAttributes[i] + '="(.*?)"','gi');
        output = output.replace(attributeStripper, '');
    }
    return output;
}