<template>
    <div>
        <draggable class="list-group"
            v-model="fees"
            group="fees"
            handle=".handle"
            draggable=".list-group-item"
            v-if="fees.length > 0"
        >
            <div class="list-group-item" v-for="(fee, index) in fees">

                <span class="handle"></span>

                <div class="row">
                    <div class="col-xs-12 col-sm-7 mb-5-xs">
                        <input type="text" class="form-control" :name="'fees['+index+'][label]'" placeholder="Description" v-model="fee.label">
                    </div>
                    <div class="col-xs-12 col-sm-4">
                        <div class="input-group">
                            <span class="input-group-addon">$</span>
                            <input type="text" class="form-control" :name="'fees['+index+'][amount]'" placeholder="Price" v-model="fee.amount">
                        </div>
                        <input type="hidden" :name="'fees['+index+'][id]'" v-if="hasOwnProperty.call(fee, 'id')" v-model="fee.id">
                    </div>
                </div>

                <div class="item-toolbar" v-if="fees.length > 0">
                    <button type="button" class="btn btn-danger btn-xs" title="Remove Fee" tabindex="-1" @click="deleteRow(index)">
                        <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>
                    </button>
                </div>

            </div>
        </draggable>
        <button type="button" class="btn btn-sm btn-primary pull-right" title="Add Fee" @click="addRow">
            <span aria-hidden="true" class="glyphicon glyphicon-plus"></span>
            Add Fee
        </button>
    </div>
</template>

<script>
    import draggable from 'vuedraggable';

    export default {
        data() {
            return {
                fees: []
            };
        },

        props: {
            old: {
                type: Array,
                default: function() {
                    return [];
                }
            }
        },

        components: {
            draggable
        },

        mounted() {
            this.prepareComponent();
        },

        methods: {

            prepareComponent() {

                if(this.old.length) {
                    this.fees = this.old;
                } else {
                    this.addRow();
                }
            },

            emptyRow() {
                return {
                    label: '',
                    amount: ''
                };
            },

            addRow() {
                this.fees.push( this.emptyRow() );
            },

            deleteRow(index) {
                var self = this;

                if(index in this.fees) {
                    if(!confirm('Are you sure you want to delete this fee?')) {
                        return;
                    }

                    var fee = this.fees[index];

                    if(fee.hasOwnProperty('id') && fee.id) {
                        axios.delete('/speakers/fee/'+fee.id)
                            .catch(e => console.log(e))
                            .then(x => x.data)
                            .then(x => {
                                self.fees.splice(index, 1);
                                //
                                return;
                            });
                    } else {
                        this.fees.splice(index, 1);
                    }
                }

                if(this.fees.length === 0) {
                    this.addRow();
                }
            }
        }
    }
</script>
