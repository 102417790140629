<template>
    <div class="list-group-item alterable" :id="'testimonial' + index">

        <span class="handle"></span>

        <h4 class="list-group-item-heading" v-html="label"></h4>
        <p class="list-group-item-text small">
            <template v-if="!!date">
                <em>{{ date }}</em> &ndash;
            </template>
            {{ excerpt }}
        </p>

        <div class="item-toolbar">
            <div class="btn-group btn-group-sm" role="group" aria-label="...">
                <button type="button" class="btn btn-info" title="Edit" tabindex="-1" @click="editItem">
                    <span class="glyphicon glyphicon-edit" aria-hidden="true"></span>
                </button>
                <button type="button" class="btn btn-danger" title="Delete" tabindex="-1" @click="deleteItem">
                    <span class="glyphicon glyphicon-trash" aria-hidden="true"></span>
                </button>
            </div>
        </div>

        <input type="hidden" :name="'testimonials['+index+'][name]'" v-model.trim="testimonial.name">
        <input type="hidden" :name="'testimonials['+index+'][title]'" v-model.trim="testimonial.title">
        <input type="hidden" :name="'testimonials['+index+'][organization]'" v-model.trim="testimonial.organization">
        <input type="hidden" :name="'testimonials['+index+'][event_date]'" v-model.trim="testimonial.event_date">
        <input type="hidden" :name="'testimonials['+index+'][text]'" v-model.trim="testimonial.text">
        <input type="hidden" :name="'testimonials['+index+'][id]'" v-if="hasOwnProperty.call(testimonial, 'id')" v-model="testimonial.id">
    </div>
</template>

<script>
    import { formatDate, limitWords } from '../../helpers.js'

    export default {
        props: {
            index: {
                type: Number,
                required: true
            },
            testimonial: {
                type: Object,
                required: true
            }
        },

        computed: {
            date() {
                if(!this.testimonial.event_date) {
                    return '';
                }
                return formatDate(this.testimonial.event_date);
            },
            excerpt() {
                return limitWords(this.testimonial.text, 35);
            },
            label() {
                var str = this.testimonial.organization;
                var meta = [];

                if(!!this.testimonial.name && this.testimonial.name !== '') {
                    meta.push(this.testimonial.name);
                }
                if(!!this.testimonial.title && this.testimonial.title !== '') {
                    meta.push(this.testimonial.title);
                }
                if(meta.length > 0) {
                    str += '<br><small class="meta">'+meta.join(', ')+'</small>';
                }

                return str;
            }
        },

        methods: {
            editItem() {
                this.$emit('edit', this.testimonial, this.index);
            },

            deleteItem() {
                if(confirm('Are you sure you want to delete this entry?')) {
                    this.$emit('delete', this.index)
                }
            }
        }
    }
</script>