<template>
    <select :name="renderName" :multiple="isMultiple" :class="className" v-model="selected">
        <option v-for="(option, key) in options" :value="key">{{ option }}</option>
    </select>
</template>

<script>
    import { isArray } from '../../helpers.js';
    import "select2";

    export default {

        data() {
            return {
                jQelement: null,
                selected: this.isMultiple ? [] : '',
                defaultConfig: {
                    // closeOnSelect: false,
                    placeholder: '',
                    theme: 'bootstrap',
                    width: '100%'
                }
            };
        },

        props: {
            className: String,
            config: {
                type: Object,
                default: function() {
                    return {};
                }
            },
            dataUrl: String,
            defaultValue: [String, Number],
            fieldName: String,
            isMultiple: {
                type: Boolean,
                default: false
            },
            options: [Object, Array],
            old: {
                type: Array,
                default: function() {
                    return [];
                }
            }
        },

        computed: {
            renderName() {
                return this.fieldName + (this.isMultiple ? '[]' : '');
            }
        },

        watch: {
            old: function(value) {
                if(!value || !value.length) {
                    return;
                }
                if(this.jQelement.data('select2')) {
                    this.jQelement.val(value).trigger('change.select2');
                    // return;
                }

                this.updateSelected(value);
            }
        },

        mounted() {
            this.prepareComponent();
        },

        methods: {

            prepareComponent() {
                var self = this;

                this.jQelement = $(this.$el);

                if(this.config.hasOwnProperty('data')) {
                    this.$set(self, 'options', self.config.data);
                    delete this.config.data;
                }
                if(this.config.hasOwnProperty('placeholder')) {
                    this.config.placeholder = {
                        id: '-1',
                        text: this.config.placeholder
                    };
                }

                this.$on('selectselected', this.updateSelected);

                this.updateSelected(
                    this.old.length ? this.old : [this.defaultValue]
                );
                this.initSelect2();

                if(this.dataUrl !== undefined) {
                    this.getOptions( this.dataUrl );
                }

                // fix for --
                this.$parent.$on('select-change', this.triggerChange)
            },

            getOptions(url) {
                var self = this;
                axios.get(url)
                    .then(response => {
                        self.$set(self, 'options', JSON.parse(response.data));
                    });
            },

            updateSelected(value) {
                if(value && value.length > 0) {
                    this.selected = this.isMultiple ? value : value[0];
                } else {
                    this.selected = this.isMultiple ? [] : '';
                }
            },

            initSelect2() {
                var self = this;
                var config = Object.assign({}, this.defaultConfig, this.config);

                if(this.isMultiple) {
                    config.closeOnSelect = false;
                }

                this.jQelement
                    .select2(config)
                    .trigger('change')
                    .on('change', function() {
                        var values = [];
                        $(this).select2('data').forEach(function(el){
                            values.push(el.id);
                        });

                        self.$emit('selectselected', values, self.fieldName);
                        self.$emit('update:old', values);
                    });
            },

            findOptionIndex(value) {
                var self = this;

                if(!!this.options && this.options.constructor === Array) {
                    return this.options.findIndex(opt => opt === value);
                }

                return Object.keys(this.options).find(key => self.options[key] === value);
            },

            triggerChange() {
                this.jQelement.trigger('change');
            }
        },

        destroyed() {
            this.jQelement.off().select2('destroy');
        }
    }
</script>