<template>
    <div :class="cardClass" :id="id">
        <header class="card-header" v-if="hasSlot('header')">"
            <slot name="header"></slot>
        </header>
        <div class="card-body">
            <h4 class="card-title">{{ title }}</h4>
            <h6 class="card-subtitle mb-2 text-muted" v-if="subtitle">{{ subtitle }}</h6>
            <slot></slot>
        </div>
        <footer class="card-footer" v-if="hasSlot('footer')">"
            <slot name="footer"></slot>
        </footer>
    </div>
</template>

<script>
    export default {
        props: ['classes', 'id', 'title', 'subtitle'],
        computed: {
            cardClass() {
                var classNames = ['card'];

                if(this.classes) {
                    if(typeof this.classes === 'string') {
                        classNames.push(this.classes);
                    } else if(typeof this.classes === 'array') {
                        this.classes.map(
                            className => classNames.push(className)
                        );
                    }
                }

                return classNames.join(' ');
            }
        },
        methods: {
            hasSlot(slotName) {
                return !!this.$slots[slotName];
            }
        }
    }
</script>