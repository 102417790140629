$(function() {

    var $snav = $('#speaker-nav');
    var $snavItems;

    function pushHash(hash) {
        if(window.history.pushState) {
            window.history.pushState(null, null, hash);
            return;
        }
        // fallback
        location.hash = hash;
    }

    function setTab() {
        var hash = window.location.hash;
        if(hash == '') {
            $snavItems.first().tab('show');
            return;
        }

        var $target = $snavItems.filter('[href="'+hash+'"]');
        if($target.length && !$target.closest('li').hasClass('active')) {
            $target.tab('show');
        }
    }

    if($snav.length) {

        $snavItems = $snav.find('a[role="tab"]');

        $(window).on('popstate', setTab);

        $snavItems.on('click', function(event) {
            var hash = event.target.getAttribute('href');

            pushHash(hash);
        }).on('shown.bs.tab', function(event){
            var $tab = $(event.target).closest('li');

            $('.btn-prev').prop('disabled', $tab.is(':first-child'));
            $('.btn-next').prop('disabled', $tab.is(':last-child'));
        });

        setTab();

        $('.btn-next').on('click', function(){
            var $next = $snavItems.closest('li').filter('.active:first').next('li');

            if($next.length) {
                var $target = $next.find('a:first');
                $target.tab('show');
                pushHash($target.attr('href'));
            }
        });

        $('.btn-prev').on('click', function(){
            var $prev = $snavItems.closest('li').filter('.active:first').prev('li');

            if($prev.length) {
                var $target = $prev.find('a:first');
                $target.tab('show');
                pushHash($target.attr('href'));
            }
        });
    }

});