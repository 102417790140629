<template>
    <div class="inline-gutter">
        <div class="row flex-row">
            <div class="col-xs-12 col-sm-6 flex-col display-flex">
                <div class="panel panel-default flex">
                    <div class="panel-subheading">
                        Available
                        <span class="badge pull-right">{{ itemCount }}</span>
                    </div>
                    <div class="list-group scroll-group small">
                        <button type="button" class="list-group-item"
                            v-for="(item, index) in items"
                            :title="'Select '+item.name"
                            @click="selectItem(index)">{{ item.name }}</button>
                        <div class="list-group-item text-muted" v-if="fetching">
                            Fetching items...
                        </div>
                    </div><!--/.list-group-->
                </div><!--/.panel-->
            </div><!--/.col-->
            <div class="col-xs-12 col-sm-6 flex-col display-flex">
                <div class="panel panel-default flex">
                    <div class="panel-subheading">
                        Selected
                        <span class="badge pull-right">{{ selectCount }}</span>
                    </div>
                    <div class="list-group scroll-group small">
                        <button type="button" class="list-group-item"
                            v-for="(item, index) in selected"
                            :title="'Remove '+item.name"
                            @click="removeItem(index)">{{ item.name }}</button>
                        <div class="list-group-item text-muted" v-if="fetching">
                            Fetching items...
                        </div>
                    </div><!--/.list-group-->
                </div><!--/.panel-->
            </div><!--/.col-->
        </div><!--/.row-->

        <input type="hidden"
            :name="fieldName+'[]'"
            :value="item.id"
            v-for="(item, index) in selected">
    </div>
</template>

<script>
    export default {

        data() {
            return {
                fetching: false,
                items: [],
                selected: []
            };
        },

        props: {
            fieldName: String,
            fetchUrl: String,
            old: {
                type: Array,
                default: function() {
                    return [];
                }
            }
        },

        computed: {
            itemCount() {
                return this.items.length;
            },
            selectCount() {
                return this.selected.length;
            }
        },

        mounted() {
            var self = this;

            if(this.fetchUrl) {
                this.fetchItems()
                    .then(data => {
                        if(self.old.length > 0) {
                            self.old.forEach(id => {
                                var index = self.items.findIndex(function(el){
                                    return el.id == id;
                                });
                                self.selectItem(index);
                            });
                        }
                    });
            }
        },

        methods: {

            selectItem(index) {
                if(!(index in this.items)) {
                    // error
                    return;
                }

                var splice = this.items.splice(index, 1);
                this.selected.push(splice[0]);

                this.selected.sort(this.arraySort);
            },

            removeItem(index) {
                if(!(index in this.selected)) {
                    // error
                    return;
                }

                var splice = this.selected.splice(index, 1);
                this.items.push(splice[0]);

                this.items.sort(this.arraySort);
            },

            fetchItems() {
                var self = this;
                this.fetching = true;

                return axios.get(this.fetchUrl)
                    .catch(err => { console.log(err) })
                    .then(response => {
                        self.items = response.data;
                        self.fetching = false;
                    });
            },

            arraySort(a, b) {
                var nameA = a.name.toUpperCase(); // ignore upper and lowercase
                var nameB = b.name.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            }
        }
    }
</script>

<style lang="scss">
    .scroll-group {
        min-height: 200px;
        max-height: 250px;
        overflow: auto;
    }

    .panel-subheading > .badge {
        margin-top: -2px;
    }
</style>