<template>
    <div>
        <draggable class="list-group"
            v-model="presentations"
            group="presentations"
            handle=".handle"
            draggable=".list-group-item"
            v-if="presentations.length > 0"
        >
            <presentation-list-item v-for="(presentation, index) in presentations"
                :presentation="presentation"
                :key="index"
                :index="index"
                :styles="presentationStyles"
                :types="presentationTypes"
                @edit="editItem(...arguments)"
                @delete="deleteItem"></presentation-list-item>
        </draggable>
        <div class="well text-center" v-else>
            Add a presentation by clicking the <em>Add Presentation</em> button&nbsp;below.
        </div>

        <utility-modal id="PresentationModal">

            <template slot="heading">Edit Presentation</template>

            <presentation-form
                slot-scope="props"
                :modal-id="props.modalId"
                :editData="editForm"
                :identifier="editIndex"
                :styles="presentationStyles"
                :types="presentationTypes"></presentation-form>

        </utility-modal>
    </div>
</template>

<script>
    import draggable from 'vuedraggable';
    import UtilityModal from '../utility/UtilityModal.vue';
    import PresentationListItem from './PresentationListItem.vue';
    import PresentationForm from './PresentationForm.vue';

    export default {
        /*
         * The component's data.
         */
        data() {
            return {
                presentations: [],
                editIndex: null,
                editForm: {
                    title: '',
                    style_ids: [],
                    presentation_type_id: 1,
                    topic_id: 0,
                    description: ''
                }
            };
        },

        props: {
            old: {
                type: Array,
                default: function() {
                    return [];
                }
            },
            presentationStyles: [Object, Array],
            presentationTypes: [Object, Array],
            topics: [Object, Array]
        },

        components: {
            draggable,
            'utility-modal': UtilityModal,
            'presentation-list-item': PresentationListItem,
            'presentation-form': PresentationForm
        },

        mounted() {
            this.prepareComponent();
        },

        methods: {

            prepareComponent() {
                if(this.old.length) {
                    this.presentations = this.old;
                }

                Event.listen('posted-presentation', this.saveItem);
            },

            saveItem(presentation, index) {
                if(typeof index !== 'undefined' && index in this.presentations) {
                    // this.$set(this.presentations, index, presentation);
                    this.presentations.splice(index, 1, presentation);

                    this.editIndex = null;
                    Object.assign(this.editForm, this.$options.data.call(this).editForm);
                    return;
                }
                this.presentations.push(presentation);
            },

            editItem(data, index) {
                Object.assign(this.editForm, data);
                this.editIndex = index;

                $('#PresentationModal').modal('show');
            },

            deleteItem(index) {
                var self = this;

                var presentation = this.presentations[index] || false;

                if(presentation) {
                    if(presentation.hasOwnProperty('id') && presentation.id) {
                        axios.delete('/presentations/'+presentation.id)
                            .catch(e => console.error(e))
                            .then(x => this.presentations.splice(index, 1));
                    } else {
                        this.presentations.splice(index, 1);
                    }

                    return;
                }

                console.log('Presentation ['+index+'] does not exist.');
            },
        }
    }
</script>
