<template>
    <div>
        <div class="dropbox upload" v-if="isInitial">
            <input type="file" multiple class="input-file" accept="*"
                :disabled="isSaving"
                @change="filesChange($event.target.files); fileCount = $event.target.files.length">
            <p>Drag your file(s) here to begin<br> or click to browse</p>
        </div>

        <div v-if="isSaving">
            <h4>Uploading {{ fileCount }} files...</h4>
            <div class="progress">
                <div class="progress-bar progress-bar-striped" :class="{'active': isSaving}" role="progressbar" :aria-valuenow="percentCompleted" aria-valuemin="0" aria-valuemax="100" :style="'width:'+percentCompleted+'%'">
                    <span class="sr-only">{{ percentCompleted }}% Complete</span>
                </div>
            </div>
        </div>

        <div v-if="isSuccess">
            <h4>Uploaded {{ uploadedFiles.length }} file(s) successfully.</h4>
            <p><button type="button" class="btn btn-default" @click="reset()">Upload more files</button></p>
        </div>
        <div v-if="isFailed">
            <h2>Uploaded failed.</h2>
            <p><button type="button" class="btn btn-default" @click="reset()">Try again</button></p>
            <pre>{{ uploadError }}</pre>
        </div>

        <div v-if="uploadedFiles.length">
            <div class="row flex-row inline-gutter">
                <div class="col-xs-12 col-sm-6 col-md-4 flex-col" v-for="(item, index) in uploadedFiles">
                    <card classes="upload-card" :title="item.title || item.origname" :subtitle="item.mime">
                        <p class="card-text">
                            <a :href="item.url" target="_blank">{{ item.origname }}</a>
                        </p>

                        <button type="button" class="btn btn-default btn-sm" v-show="!item.editing" @click.stop="setEditing(index)">Edit</button>

                        <div :class="item.editing ? 'show' : 'hide'">
                            <div class="form-group">
                                <label class="sr-only" :for="'title'+item.id">Title</label>
                                <div class="col-xs-12">
                                    <input type="text" :id="'title'+item.id" class="form-control input-sm" placeholder="Title" v-model="item.title">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12">
                                    <button type="button" class="btn btn-primary btn-sm" @click="saveFile(index)">Update</button>
                                </div>
                            </div>
                        </div>

                        <input type="hidden" :name="'files['+index+']'" :value="item.id">
                    </card>
                </div>
            </div><!--/.row-->
        </div>

    </div>
</template>

<script>
    import card from '../utility/BootstrapCard.vue';

    const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

    export default {

        data() {
            return {
                uploadedFiles: [],
                uploadError: null,
                currentStatus: null,
                percentCompleted: 0,
                uploadFieldName: 'uploadFiles'
            }
        },

        computed: {
            isInitial() {
                return this.currentStatus === STATUS_INITIAL;
            },
            isSaving() {
                return this.currentStatus === STATUS_SAVING;
            },
            isSuccess() {
                return this.currentStatus === STATUS_SUCCESS;
            },
            isFailed() {
                return this.currentStatus === STATUS_FAILED;
            }
        },

        components: {
            card
        },

        mounted() {
            this.reset();
        },

        methods: {
            reset() {
                // reset form to initial state
                this.currentStatus = STATUS_INITIAL;
                // this.uploadedFiles = [];
                this.uploadError = null;
                this.percentCompleted = 0;
            },

            filesChange(fileList) {
                // handle file changes
                const formData = new FormData();

                if (!fileList.length) return;

                // append the files to FormData
                Array
                    .from(Array(fileList.length).keys())
                    .map(x => {
                        formData.append(
                            this.uploadFieldName+'['+x+']',
                            fileList[x],
                            fileList[x].name
                        );
                    });

                // save it
                this.handle(formData);
            },

            handle(formData) {
                var self = this;

                // upload data to the server
                this.currentStatus = STATUS_SAVING;

                this.upload(formData)
                    .then(this.wait(500)) // dev
                    .then(x => {
                        self.uploadedFiles = self.uploadedFiles.concat(x);

                        this.currentStatus = STATUS_SUCCESS;
                    })
                    .catch(err => {
                        this.uploadError = err.response;
                        this.currentStatus = STATUS_FAILED;
                    });
            },

            upload(formData) {
                var config = {
                    onUploadProgress: this.uploadProgress.bind(this)
                };

                return axios.post('/media/upload', formData, config)
                    // get data
                    .then(x => x.data)
                    // add url field
                    .then(x => x.map(file => Object.assign(
                        {},
                        file
                        // { url: `/storage/${file.local_uri}` }
                    )));
            },

            setEditing(index) {
                var item = this.uploadedFiles[index];
                    item.editing = true;

                this.uploadedFiles.splice(index, 1, item);
            },

            saveFile(index) {
                const formData = new FormData();
                var item = this.uploadedFiles[index];

                Object.keys(item).forEach(key => {
                    formData.append(key, item[key]);
                });

                axios.post('/media/update/'+item.id+'?_method=PATCH', formData)
                    .catch(e => console.log(e))
                    .then(x => x.data)
                    .then(x => {
                        // x.url = '/storage/'+x.local_uri;

                        this.uploadedFiles.splice(index, 1, x);
                    });
            },

            uploadProgress(progressEvent) {
                this.percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
            },

            wait(ms) {
                return (x) => {
                    return new Promise(resolve => setTimeout(() => resolve(x), ms));
                };
            }
        }
    }
</script>

<style lang="scss">
    .dropbox {
        align-items: center;
        background-color: lighten(#3097D1, 45%);
        border: 2px dashed darken(#f5f8fa, 10%);
        color: #636b6f;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        min-height: 100px;
        overflow: hidden;
        position: relative;
        transition: background-color .25s ease;

        &:hover {
            background-color: lighten(#3097D1, 40%);
        }

        p {
            margin-bottom: 0;
            text-align: center;
        }
    }

    .input-file {
        cursor: pointer;
        height: 100px;
        opacity: 0;
        position: absolute;
        width: 100%;
    }
</style>