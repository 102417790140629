<template>
    <div class="existing-files">
        <div class="list-group" v-if="files.length > 0">
            <div class="list-group-item" v-for="(file, index) in files">
                <span class="pull-right">
                    <a class="text-muted" style="display:inline-block" href="javascript:void(0)" @click.stop="setEdit(index)">
                        <span class="glyphicon glyphicon-edit"></span>
                        <span class="sr-only">Edit Media</span>
                    </a>
                    <a class="text-danger" style="margin-left:5px;display:inline-block;" href="javascript:void(0)" @click.stop="deleteFile(index)">
                        <span class="glyphicon glyphicon-remove"></span>
                        <span class="sr-only">Delete Media</span>
                    </a>
                </span>

                {{ title(file) }}<br>
                <a class="small" :href="fileURL(file)" target="_blank">View File</a>

                <div class="list-group-item-form" v-if="file.editing">
                    <div class="form-group">
                        <div class="col-xs-12">
                            <label :for="'ft'+index">Title</label>
                            <input type="text" class="form-control"
                                :id="'ft'+index"
                                :value="file.title"
                                :ref="'title'+index">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12">
                            <button type="button" class="btn btn-primary btn-sm" @click="saveFile(index)">Update</button>
                            <a href="javascript:void(0)" class="btn btn-alt" @click.stop="stopEdit(index)">Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="well text-center" v-else>
            No media has been uploaded.
        </div>
    </div>
</template>

<script>
    export default {

        data() {
            return {
                files: []
            }
        },

        props: {
            speaker_id: {
                type: Number,
                default: 0
            }
        },

        mounted() {
            this.prepareComponent();
        },

        methods: {

            prepareComponent() {
                if(this.speaker_id) {
                    this.fetchFiles(this.speaker_id);
                }
            },

            fileURL(file) {
                return '/storage/' + file.local_uri;
            },

            title(file) {
                return file.title || file.origname;
            },

            setEdit(index) {
                var item = this.files[index];
                    item.editing = true;

                this.files.splice(index, 1, item);
            },

            stopEdit(index) {
                var item = this.files[index];
                    item.editing = false;

                this.files.splice(index, 1, item);

                // reset input value
                this.$refs['title'+index][0].value = item.title;
            },

            fetchFiles(speaker_id) {
                var self = this;

                axios.get('/speakers/media', {
                    params: {
                        id: speaker_id
                    }
                })
                    .catch(err => { console.log(err) })
                    .then(d => d.data)
                    .then(d => {
                        self.files = d.media;
                    });
            },

            saveFile(index) {
                var self = this;

                const formData = new FormData();
                var item = this.files[index];

                Object.keys(item).forEach(key => {
                    var value = (key == 'title') ? this.$refs['title'+index][0].value : item[key];
                    formData.append(key, value);
                });

                axios.post('/media/update/'+item.id+'?_method=PATCH', formData)
                    .catch(e => console.log(e))
                    .then(x => x.data)
                    .then(x => {
                        this.files.splice(index, 1, x);
                    });
            },

            deleteFile(index) {
                var self = this;
                var item = this.files[index];

                if(confirm('Are you sure you want to delete \'' + this.title(item) + '\'?')) {
                    axios.get('/speakers/media/'+item.id+'/delete')
                        .catch(e => console.log(e))
                        .then(x => x.data)
                        .then(x => {
                            //
                            console.log(x);

                            self.files.splice(index, 1);
                        });
                }
            }

        }
    }
</script>