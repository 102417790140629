
$(function(){

    $('img[data-lazy]').each(function(){
        var img = document.createElement('img');
        img.addEventListener('load', function(){
            this.src = img.src;
            img = null;
        }.bind(this));

        img.src = this.getAttribute('data-lazy');
    });

});
