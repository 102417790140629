
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import Vue from 'vue';
import Clients from './components/passport/Clients';
import AuthorizedClients from './components/passport/AuthorizedClients';
import PersonalAccessTokens from './components/passport/PersonalAccessTokens';
import UtilityModal from './components/utility/UtilityModal';
import TestimonialList from './components/speaker/TestimonialList';
import TestimonialForm from './components/speaker/TestimonialForm';
import PresentationList from './components/speaker/PresentationList';
import PrettySelect from './components/utility/PrettySelect';
import PresentationForm from './components/speaker/PresentationForm';
import SpeakerFees from './components/speaker/SpeakerFees';
import FileUpload from './components/utility/FileUpload';
import SpeakerVideos from './components/speaker/SpeakerVideos';
import SpeakerFiles from './components/speaker/SpeakerFiles';
import ImageCropper from './components/speaker/ImageCropper';
import DualListSelector from './components/utility/DualListSelector';

window.Vue = Vue;

window.VeeValidate = require('vee-validate');
Vue.use(VeeValidate);

window.Raven = require('raven-js');
window.RavenVue = require('raven-js/plugins/vue');

Raven.config(process.env.MIX_SENTRY_DSN_PUBLIC)
    .addPlugin(RavenVue, Vue)
    .install();

/**
 * Events instance
 */
window.Event = new class {
    constructor() {
        this.vue = new Vue();
    }

    fire(event, data) {
        this.vue.$emit(...arguments);
    }

    listen(event, callback) {
        this.vue.$on(event, callback);
    }
};

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// Vue.component('example', require('./components/Example.vue'));

Vue.component('passport-clients', Clients);
Vue.component('passport-authorized-clients', AuthorizedClients);
Vue.component('passport-personal-access-tokens', PersonalAccessTokens);
// Vue.component('profile-photo', require('./components/speaker/ProfilePhoto.vue'));
Vue.component('utility-modal', UtilityModal);
Vue.component('testimonial-list', TestimonialList);
Vue.component('testimonial-form', TestimonialForm);
Vue.component('presentation-list', PresentationList);
Vue.component('presentation-form', PresentationForm);
Vue.component('speaker-fees', SpeakerFees);
Vue.component('file-upload', FileUpload);
Vue.component('speaker-videos', SpeakerVideos);
Vue.component('speaker-files', SpeakerFiles);
Vue.component('pretty-select', PrettySelect);
Vue.component('image-cropper', ImageCropper);
Vue.component('dual-list', DualListSelector);

const app = new Vue({
    el: '#app',
    data: {
        countries: {},
        industries: {},
        languages: {},
        presentation_styles: {},
        presentation_types: {},
        topics: {},
        websites: {}
    },
    created() {
        var self = this;

        var dataTypes = ['countries', 'industries', 'languages', 'presentation_styles', 'presentation_types', 'topics', 'websites'];
        for(let dataType of dataTypes) {
            axios.get('/fetch/'+dataType)
                .then(x => x.data)
                .then(x => {
                    var data = typeof x === 'object' ? x : JSON.parse(x);

                    self.$set(self, dataType, data);

                    Event.fire('loaded:'+dataType);
                });
        }

    }
});
